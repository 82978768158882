/**
 * Header
 *
 * Summary:
 *
 *  0. Banner
 *  1. Page Header
*/

/* ==========================================================================
   0. Banner
   ========================================================================== */
header.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: top .3s;
  background-color: $white;
  border-bottom: 1px solid $chriscan-light-gray;
  .brand  {
    margin: ($spacer * 1.5) 0 1.3rem;
    display: inline-block;
    img {
      width: 160px;
      height: auto;
      @include media-breakpoint-down(md) {
        width: 90px;
      }
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    .navigation {
      margin: ($spacer * 2) 0 1.8rem;
      display: flex;
      @include media-breakpoint-down(md) {
        display: block;
      }
      .navbar-collapse {
        @include media-breakpoint-down(md) {
          display: block;
          position: fixed;
          width: 100%;
          height: 100vh;
          top: -110%;
          left: 0;
          background-color: $chriscan-light-gray;
          z-index: -100;
          opacity: 0;
          transition: .2s;
          padding-top: 5rem;
          padding-left: 1rem;
        }
      }
      a {
        font-family: $font-family-alternate;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: .15s;
        font-size: 1rem;
        @include media-breakpoint-down(md) {
          font-size: 2.5rem;
        }
      }
      .current-menu-item a,
      .active a {
        color: $chriscan-red;
      }
      .navbar-toggler {
        position: relative;
        outline: none;
        z-index: 101;
        width: 30px;
        height: 17px;
        background-color: transparent;
        border: none;
        transition: .3s;
        cursor: pointer;
        @include media-breakpoint-down(xs) {
          right: 20px;
        }
        .hamburger-top-line {
          display: block;
          width: 100%;
          height: 1px;
          background-color: $chriscan-red;
          position: absolute;
          top: 0;
          left: 0;
          transition: .3s;
        }
        .hamburger-mid-line {
          @extend .hamburger-top-line;
          top: 8px;
          left: 5px;
        }
        .hamburger-bot-line {
          @extend .hamburger-top-line;
          top: auto;
          bottom: 0;
        }
        &:hover {
          .hamburger-mid-line {
            left: 9px;
          }
          .hamburger-bot-line {
            left: -4px;
          }
        }

        &[aria-expanded="true"] {
          .hamburger-top-line {
            transform: rotate(45deg);
            top: 8px;
          }
          .hamburger-mid-line {
            background-color: transparent;
            transform: none;
          }
          .hamburger-bot-line {
            transform: rotate(-45deg);
            left: 0;
            top: 8px;
          }
        }
      }
    }
    .contact-links {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;

      @include media-breakpoint-down(md) {
        margin-top: 1rem;
        margin-right: $spacer * 6;
      }

      li ~ li {
        margin-left: $spacer * 1.5;
        @include media-breakpoint-down(md) {
          margin-left: $spacer * 2.5;
        }
      }
      a {
        padding: 1.5rem 0;
      }
    }
  }
  .menu-item {
    font-family: $font-family-alternate;
    margin-right: $spacer * 2;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    a {
      padding: 1.5rem 0;
    }
  }
  .relative-block {
    position: relative;
  }
  .show {
    z-index: 100!important;
    opacity: 1!important;
    top: 0!important;
  }
}
.fixed_top_hide {
  top: -10rem!important;
}
.fixed_top_show {
  top: 0!important;
}

/* ==========================================================================
   1. Page Header
   ========================================================================== */
section.page-header {
  margin-top: 5.3rem;
  @extend .section;
  padding: ($spacer * 3) 0 ($spacer * 8);
  @include media-breakpoint-up(md) {
    padding: ($spacer * 4) 0 ($spacer * 8);
  }
  // Default
  .content {
    @extend .col-sm-12;
    overflow: hidden;
    h1 {
      margin-bottom: 0;
      font-size: $display3-size;
      font-weight: $headings-font-weight;
      padding-bottom: $spacer / 2;
      position: relative;
      @include media-breakpoint-down(md) {
        font-size: 2.3rem;
      }
      span,
      b {
        position: relative;
        display: inline-block;
      }
    }
    br {
      @include media-breakpoint-down(md) {
        display: none
      }
    }
    .subtitle {
      @extend .lead;
      margin-top: $spacer;
      line-height: $line-height-sm;
      margin-bottom: 0;

      b {
        @include media-breakpoint-down(lg) {
          width: 100%;
          display: inline-block;
        }

        &:before {
          content: '';
          display: inline-block;
          width: 15%;
          height: 2px;
          background-color: $chriscan-light-gray;
          margin-right: $spacer;
          margin-bottom: $spacer;

          @include media-breakpoint-down(sm) {
            width: 10%;
          }
        }
      }
    }
  }

  // Without Image
  &.without-image {
    @extend .padding-no-bottom;
    // Padding: Default
    padding-top: ($spacer * 3);
    h1 {
      padding-top: $spacer * 2;
      border-top: 1px solid $chriscan-light-gray;
      line-height: 1.3;
    }
  }

  // With Image
  &.with-image {
    @extend .bg-image;
    @extend .bg-cover-transparency;
    .content {
      @extend .col-sm-10;
      @extend .col-lg-7;
    }

    &.no-slider {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        z-index: 4;
      }
      .container {
        position: relative;
        z-index: 5;
      }

      h1,
      b,
      .subtitle {
        color: $white;
      }
    }
  }

  // Featured
  &.featured {
    .content {
      @extend .col-sm-10;
      @extend .col-lg-8;
      .subtitle {
        margin-bottom: 0;
        margin-top: 0;
        font-size: $font-size-base;
        font-weight: 200;
        b {
          font-weight: 200;
          color: $blue;
        }
      }
    }
  }

  // Slider
  &.slider {
    padding: 0!important;

    .swiper-slide {
      padding: ($spacer * 3) 0 ($spacer * 8);
      height: initial;
      background-size: cover!important;

      @include media-breakpoint-up(md) {
        padding: ($spacer * 4) 0 ($spacer * 11);
      }

      &:after {
        content: '';
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(to top, #222, transparent );
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      bottom: 5rem;
    }
    .swiper-pagination-bullet {
      width: 25%;
      height: 2rem;
      border-radius: 0;
      background-color: transparent;
      border-top: 2px solid $chriscan-light-gray;
      color: $chriscan-light-gray;
      padding-top: $spacer;
      margin: 0 2rem;
      opacity: .8;
      font-family: $font-family-alternate;
      letter-spacing: 1px;
      font-size: 1rem;
      @include media-breakpoint-down(sm) {
        margin: 0 1rem;
      }
      &-active {
        color: $white;
        border-color: $white;
        opacity: 1;
      }
    }
    .bullet-title {
      display: none;
    }
  }

  .white-wrap {
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      width: 70%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: $white;
      @include media-breakpoint-down(md) {
        width: 85%;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
        opacity: .8;
      }
    }
    .container {
      box-sizing: border-box;
    }
    .content {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}