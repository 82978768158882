footer.section {
  padding-bottom: 1rem!important;
}
.content-info {
  @extend .section;
  @extend .padding-small;
  border-top: 1px solid $chriscan-light-gray;
  .widgets {
    @include make-row();
    justify-content: space-between;
    ul {
      margin-bottom: 0;
    }
    .about,
    .widget_text {
      @include media-breakpoint-down(md) {
        max-width: 100%;
        flex: 100%;
      }
      p {
        padding: 0 ($spacer * 2.5);
        color: $chriscan-gray;
      }
      .images img {
        margin-left: $spacer * 2.5;
        margin-top: $spacer;
      }
    }
    .widget {
      margin-bottom: ($spacer * 1.5);
      @extend .col-xl-4;
      @extend .col-lg-6;
      @extend .col-md-6;
      h4,
      h3 {
        margin-bottom: $spacer * 2;
        font-weight: 600;
        color: $chriscan-dark-gray;
        &:before {
          content: '';
          display: inline-block;
          width: 1px;
          height: 1rem;
          background-color: $chriscan-red;
          margin-right: $spacer * 2.5;
        }
      }
      .twit-feed {
        @extend .list-unstyled;
        li {
          padding: 0 ($spacer * 2.5) ($spacer * 1.5);
          color: $chriscan-gray;
          position: relative;

          &:before {
            content: '\f099';
            position: absolute;
            top: 0;
            left: 0;
            font-family: FontAwesome;
            color: $chriscan-light-gray;
            font-size: 1.3rem;
            line-height: 1.3rem;
          }
        }
        li ~ li {
          border-top: 1px solid $chriscan-light-gray;
          padding-top: $spacer * 1.5;
          &:before {
            top: 1.5rem;
          }
        }
        .twit-data {
          color: $chriscan-light-gray;
        }
      }

      .contact-links {
        @extend .list-unstyled;
        li {
          padding: 0 ($spacer * 2.5) ($spacer * .5);
          position: relative;
          i {
            position: absolute;
            top: 0;
            left: 0;
            color: $chriscan-light-gray;
            font-size: .85rem;
            line-height: 1rem;
            transition: .2s;
          }
          a {
            display: block;
            &:hover i {
              color: $chriscan-red;
            }
          }
        }
        li ~ li {
          border-top: 1px solid $chriscan-light-gray;
          padding-top: $spacer * .5;
          i {
            top: .5rem;
          }
        }
      }

      .subscribe {
        margin-top: $spacer * 2;
        input[type='email'],
        input[type='text']{
          width: 100%;
          border: none;
          border-bottom: 1px solid $chriscan-dark-gray;
          background-color: transparent;
          outline: none;
          padding-left: 2.5rem;
          padding-right: 5rem;
        }
        .gfield_label {
          display: none;
        }

        //span {
        //  @extend .cta;
        //  line-height: 1rem;
        //  margin-top: 0;
        //  &:before {
        //    margin-right: .7rem;
        //  }
        //}
        .error-border {
          border-color: $chriscan-red!important;
        }
        .message {
          color: transparent;
          padding: ($spacer / 2) ($spacer * 2.5) 0;
        }
        .show-message {
          color: lighten($chriscan-red, 10%);
        }

      }
      .gform_wrapper form {
        position: relative;
        .gform_button {
          color: $chriscan-red;
          border: none;
          background-color: transparent;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          outline: none;
          width: auto;
          //&:hover {
          //  span:before {
          //    margin-right: .7rem;
          //  }
          //}
        }
      }
      @include make-col-ready();
    }
  }
}
.copywriting p {
  text-align: center;
  color: $chriscan-light-gray;
}
.awards {
  text-align: center;
  margin-bottom: $spacer;
}
.social-media {
  @extend .centered;
  .social-links {
    font-size: $h2-font-size;
    @extend .list-inline;
    li {
      @extend .list-inline-item;
    }
  }
}