/**
 * Pages
 *
 * Summary:
 *
 *  0. Page Builder
 *  - 0.1 Section "Content"
 *  - 0.2 Section "Featured Blocks"
 *  - 0.3 Section "Gallery"
 *  - 0.4 Section "Latest News"
 *  - 0.5 Section "Subnavigation"
 *  1. Default Page
 *  2. Posts Page
*/

/* ==========================================================================
   0. Page Builder
   ========================================================================== */

  /* 0.1 Section "Content"
   ========================================================================== */
  .section.content {

  }

  h1.section-title {
    @extend .col-12;
    letter-spacing: 2px;
    font-weight: 600;
    color: $chriscan-dark-gray;
    margin-bottom: $spacer * 3;
    padding-left: 3rem;
    position: relative;

    @include media-breakpoint-down(md) {
      font-size: 2.3rem;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 90%;
      background-color: $chriscan-red;
    }
  }

  /* 0.2 Section "Featured Blocks"
   ========================================================================== */
  .section.featured_blocks {
    .featured-list {
      counter-reset: item;

      @extend .col-12;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include media-breakpoint-down(md) {
        justify-content: space-around;
      }

      .item {
        width: 30%;
        padding: 0;
        position: relative;
        z-index: 2;
        @include media-breakpoint-down(md) {
          width: 45%;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-bottom: $spacer;
        }
        a {
          padding: ($spacer * 3) 0;
          display: inline-block;
          width: 100%;

          height: 333px;

          background-position-x: right!important;
          background-repeat: no-repeat!important;
          background-size: 85%!important;
        }

        &:hover {
          cursor: pointer;
          &:after {
            background-color: $chriscan-red;
          }
          &:before {
            color: $chriscan-red;
          }
        }
        &:after {
          content: '';
          width: 1px;
          height: calc(100% - 8rem);
          position: absolute;
          z-index: 1;
          top: 5rem;
          left: 0;
          background-color: $chriscan-light-gray;
          transition: .2s;
        }
        &:before {
          counter-increment: item;
          content: counter(item);
          position: absolute;
          z-index: 2;
          bottom: 0;
          left: 0;
          color: $chriscan-light-gray;
          transform: translateX(-50%);
          transition: .2s;
        }
      }
      .title {
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 2px;
        color: $chriscan-dark-gray;
      }
    }
  }

  /* 0.3 Section "Gallery"
  ========================================================================== */
  .section.gallery {
    .swiper-container {
      padding: 0 0 20px;
    }

    .gallery-navigation {
      //position: absolute;
      //top: 50%;
      //right: 50px;
      //height: 35px;
      //width: 10px;

      height: 30px;
      top: 30px;
      bottom: 0;
      left: 0;
      width: 100%;
      position: relative;
    }

    .swiper-slide {
      @extend .shadow;
      overflow: hidden;
      height: 182px;
      & > a {
        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 50%;
      right: 0;
      background: transparent no-repeat;
      width: 20px;
      height: 10px;
    }

    .swiper-button-prev {
      @include img-retina('../images/icon-arrow-left-small.png', '../images/icon-arrow-left-small@2x.png', 20px, 5px);
      top: 0;
      left: 0;
      right: auto;
    }

    .swiper-button-next {
      @include img-retina('../images/icon-arrow-right-small.png', '../images/icon-arrow-right-small@2x.png', 20px, 5px);
      top: 0;
      right: 0;
    }

    @include media-breakpoint-down(sm) {
      .gallery-navigation {
        top: 30px;
        bottom: 0;
        left: 0;
        width: 100%;
        position: relative;

        .swiper-button-prev {
          left: 0;
          right: auto;
        }

        .swiper-button-next {
          top: 0;
          right: 0;
        }
      }
    }
  }

  .pswp__button--arrow--left:before,
  .pswp__button--arrow--right:before {
    background-image: url(../images/default-skin.png);
  }

  @media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
    .pswp--svg .pswp__button--arrow--left:before,
    .pswp--svg .pswp__button--arrow--right:before {
      background-image: url(../images/default-skin.svg); }
  }

  .pswp__preloader--active .pswp__preloader__icn {
    background-image: url(../images/preloader.gif);
  }

  /* 0.4 Section "Latest News"
  ========================================================================== */
  .section.latest_news {
    .news-list {
      @extend .col-12;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include media-breakpoint-down(md) {
        justify-content: space-around;
      }
      .news-item {
        width: 30%;
        position: relative;
        display: inline-block;
        z-index: 2;
        margin-bottom: $spacer;
        @include media-breakpoint-down(md) {
          width: 45%;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-bottom: $spacer;
        }
        .post_thumbnail {
          display: block;
          width: 100%;
          background-size: cover!important;
          transition: .2s;
        }
        a {
          width: 100%;
          display: inline-block;
          &:hover {
            .latest_post_title {
              color: $chriscan-dark-gray;
            }
            .read-more {
              &:before {
                width: 20px;
                margin-right: .5rem;
              }
            }
          }
        }
        .latest_post_title {
          margin: ($spacer * 1.5) 0 .5rem;
          font-weight: 600;
          letter-spacing: 1px;
        }

        &:hover {
          .post_thumbnail {
            opacity: .7;
          }
        }
      }
      .read-more {
        @extend .cta;
        margin-top: 0;
      }
    }
  }

  /* 0.5 Section "Subnavigation"
  ========================================================================== */
  .section.subnavigation {
    position: relative;
    z-index: 1;
    padding-top: 0;
    section.subnavigation {
      overflow: hidden;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
    section.subnavigation {
      background-color: transparent;
      transition: background-color .2s, top .3s;
      padding-top: $spacer;
    }
    .fixed {
      position: fixed;
      top: 1.75rem;
      left: 50%;
      z-index: 100;
      width: 100%;
      transform: translate(-50%, 0);
      background-color: rgba($chriscan-light-gray, .9)!important;
      .sub-row {
        padding: 0 15px;
        &:after {
          width: 100vw;
        }
      }
      .subnav-list {
        @include media-breakpoint-down(sm) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    .fixed-up {
      top: 7.4rem;
    }
    .sub-row {
      position: relative;
      justify-content: space-between;
      transition: padding-bottom .3s;
      &:after {
        content: '';
        position: absolute;
        top: 2.2rem;
        left: 50%;
        width: 100%;
        transform: translate(-50%, 0);
        transition: .2s;
        height: 1px;
        background-color: $chriscan-gray;
      }
      .home-link {
        color: $chriscan-dark-gray;
        font-size: 1.1rem;
        margin-bottom: map-get($spacers, 2);
        display: inline-block;
        text-transform: none;
      }
    }
    .subnav-list {
      margin: 0;
      padding-bottom: .5rem;
      padding-left: 0;
      list-style: none;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 35px;
        left: 0;
        margin: 0;
      }
    }
    li {
      display: inline;
      margin-left: 0;
      margin-right: $spacer;
     }
    a {
      font-size: $h3-font-size;
      white-space: nowrap;
      padding: 0!important;
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
      }
      &.active {
        background-color: transparent;
        color: $chriscan-red;
      }
    }
  }

/* ==========================================================================
   1. Default Page
   ========================================================================== */
.page {
  section.content {
    @extend .section;
  }
}

/* ==========================================================================
   2. Posts Page
   ========================================================================== */
.posts-container {
  margin: ($spacer * 5) auto;
  p,
  time.updated {
    color:$chriscan-dark-gray;
  }

}
.post-single {
  margin-top: ($spacer * 10);
}
.post-featured-img {
  height: $spacer * 25;
  background-size: cover;
  margin-bottom: $spacer * -3;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient( to top, $black, rgba(0, 0, 0, 0));
    z-index: 4;
  }
}
.main-inner-posts {
  padding-right: ($spacer * 5);
  article {
    margin-bottom: map-get($spacers, 5);
  }
  .post-categories {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    li {
      padding-left: map-get($spacers, 2);
    }
  }
  .entry-title {
    margin: map-get($spacers, 1) 0 map-get($spacers, 4);
    display: inline-block;
    a:hover {
      text-decoration: none;
    }
  }
  .more_link {
    color: $chriscan-red;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    &:hover {
      text-decoration: none;
      //&:after {
      //  margin-left: $spacer;
      //}
    }
    &:after {
      content: '';
      width: 25px;
      height: 10px;
      display: inline-block;
      background: url(../images/icon-arrow-right-small@2x.png) center center no-repeat;
      background-size: contain;
      margin-left: map-get($spacers, 2);
      transition: .2s;
    }
  }
}