.ngg-galleryoverview {
  text-align: center;
}

.slideshowlink {
  text-align: left;
  margin-bottom: $spacer;
}

.ngg-gallery-thumbnail {
  border: none!important;
  img {
    transition: .2s;
  }
  &:hover {
    img {
      box-shadow: 0 0 11px 2px rgba(0,0,0,0.75);
    }
  }
}