/**
 * Forms
 *
 * Summary:
 *
 *  0. Search
 *  - 0.1 Inline Search
 *  - 0.2 Default Search
 *  1. Gravity Forms
*/

/* ==========================================================================
   0. Search
   ========================================================================== */

/* 0.1 Default Search
   ========================================================================== */
.search-form {
  @extend .form-inline;
  .search-field {
    @extend .form-control;
  }
  .search-submit {
    @extend .btn;
    @extend .btn-secondary;
    padding-top: .65rem;
    padding-bottom: .65rem;
    margin-left: $spacer;
  }
  label {
    font-weight: normal;
    @extend .form-group;
  }
  @include media-breakpoint-down(sm) {
    label {
      width: 100%;
      .search-field {
        width: 100%;
      }
      margin: 0 0 $spacer;
    }
    .search-submit {
      margin: 0;
    }
  }
}

/* 0.1 Inline Search
   ========================================================================== */
header.utility {
  .search-form {
    color: $gray-800;
    .search-field {
      width: 130px;
      border: none;
      border-bottom: 1px solid $gray-500;
      border-radius: 0;
      font-size: $font-size-sm;
      transition: .2s ease;
      padding: 0 .75rem 0 .15rem;
      margin-right: .5rem;
      color: $gray-800;
      &::placeholder {
        color: $gray-800;
      }
      &:focus {
        width: 240px;
        border-bottom-color: $gray-800;
        color: $gray-800;
        &::placeholder {
          color: $gray-900;
        }
      }
    }
    .form-submit-label {
      cursor: pointer;
    }
    .search-submit {
      display: none;
    }
  }
}

/* ==========================================================================
   Gravity Forms
   ========================================================================== */
.gform_wrapper {
  margin-bottom: $spacer;
  font-family: $font-family-sans-serif;
  font-weight: 300;
}

.gform_wrapper ul {
  @extend .list-unstyled;
}

.gform_wrapper li {
  @extend .form-group;
}

.gform_wrapper .hidden-input {
  .gfield_label {
    @extend .sr-only;
  }
}

.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: $red;
}

.gform_wrapper .hidden-input {
  padding-left: 1px;
  color: $red;
}

.ginput_container, .form-group {
  input,
  select,
  textarea {
    @extend .form-control;
    background-color: white;
  }
  
  input[type=radio], input[type=checkbox] {
    display: inline;
    width: auto;
    box-shadow: none;
    border: none;
  }
  
  .gfield_checkbox > li,
  .gfield_radio > li {
    margin-bottom: 0;
    display: table;
    > input,
    > label {
      display: table-cell;
      vertical-align: middle;
    }
    > label {
      padding-left: 5px;
      line-height: 1.7;
    }
  }
}

.gfield.choises_inline > .ginput_container {
  .gfield_checkbox > li,
  .gfield_radio > li {
    display: inline-block;
    &:not(:last-child) {
      padding-right: $spacer;
    }
    input, label {
      display: inline;
    }
  }
}

.ginput_container textarea {
  height: auto;
}

.gform_button {
  @extend .btn;
  @extend .btn-primary;
}

.gform_wrapper .gfield_error {
  .gfield_label {
    color: $red;
  }
  input,
  select,
  textarea {
    background-color: $red;
    color: $red;
    @include form-control-focus();
  }
}
.validation_error {
  @extend .alert;
  @extend .alert-danger;
}
#gforms_confirmation_message {
  @extend .alert;
  @extend .alert-success;
}

.gform_confirmation_message {
    min-height: 200px;
    text-align: center;
}

/**
 * Gravity Forms CSS Selector Columns
 */
// Builds a half column for .gf_left_half, .gf_right_half, .ginput_left and .ginput_right
li.gfield.gf_left_half, li.gfield.gf_right_half, .ginput_complex .ginput_left, .ginput_complex .ginput_right {
  @extend .col-sm-6;
  display: inline-block;
  vertical-align: top;
}

li.gfield.gf_left_half {
  padding-left: 0;
  padding-right: floor($spacer);
  clear: left;

}
.gform_wrapper ul.gform_fields li.gfield.gf_left_half {
  @include media-breakpoint-up(sm) {
    padding-right: 15px;
  }
}
li.gfield.gf_right_half {
  padding-right: 0;
  padding-left: floor($spacer);
}
@include media-breakpoint-down(xs) {
  li.gfield.gf_left_half {
    padding-right: 0;
  }
  li.gfield.gf_right_half {
    padding-left: 0;
  }
}

// Builds a 1 quarter column for .gf_one_quarter
li.gfield.gf_one_quarter {
  @extend .col-md-3;
}

// Builds a 3 quarter column for .gf_three_quarter
li.gfield.gf_three_quarter {
  @extend .col-md-9;;
  padding: 0;
}

// Builds a 1 third column for .gf_left_third, .gf_middle_third, .gf_right_third
li.gfield.gf_left_third, li.gfield.gf_middle_third, li.gfield.gf_right_third {
  @extend .col-md-4;
  display: inline-block;
  vertical-align: top;
}
li.gfield.gf_left_third {
  padding-left: 0;
  clear: left;
}
li.gfield.gf_right_third {
  padding-right: 0;
}
@include media-breakpoint-down(sm) {
  li.gfield.gf_middle_third,
  li.gfield.gf_left_third {
    padding-right: 0;
  }
  li.gfield.gf_middle_third,
  li.gfield.gf_right_third {
    padding-left: 0;
  }
}

// 2 column lists for radio and checkboxes
li.gfield.gf_list_2col ul.gfield_checkbox li, li.gfield.gf_list_2col ul.gfield_radio li {
  margin: 0;
  min-height: 1.8em;
  @extend .col-md-6;

  // without converting to inline block display the list will be in 1 column on smaller displays
  @include media-breakpoint-down(md) {
    display: inline-block;
  }
}

// 3 column lists for radio and checkboxes
li.gfield.gf_list_3col ul.gfield_checkbox li, li.gfield.gf_list_3col ul.gfield_radio li {
  margin: 0;
  min-height: 1.8em;
  @extend .col-md-4;

  // without converting to inline block display the list will be in 1 column on smaller displays
  @include media-breakpoint-down(md) {
    display: inline-block;
  }
}

// 4 column lists for radio and checkboxes
li.gfield.gf_list_4col ul.gfield_checkbox li, li.gfield.gf_list_4col ul.gfield_radio li {
  margin: 0;
  min-height: 1.8em;
  @extend .col-md-3;

  // without converting to inline block display the list will be in 1 column on smaller displays
  @include media-breakpoint-down(md) {
    display: inline-block;
  }
}

// Complex columns fix
.ginput_complex .ginput_left {
  padding-left: 0;
}
.ginput_complex .ginput_right {
  padding-right: 0;
}

.gform_wrapper .ginput_complex.ginput_container.has_first_name.has_middle_name.no_last_name span,
  .gform_wrapper .ginput_complex.ginput_container.has_first_name.no_middle_name.has_last_name span,
  .gform_wrapper .ginput_complex.ginput_container.no_first_name.has_middle_name.has_last_name span {
  float: left;
  vertical-align: top;
  width: 50%;
  margin: 0;
  box-sizing: border-box;
  margin-bottom: 1rem;
  &.name_first {
    padding-right: 1rem;
  }
  &.name_last {
    padding-left: 1rem;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;

    &.name_first {
      padding-right: 0px;
    }
    &.name_last {
      padding-left: 0px;
    }
  }
}
.gform_body {
  .hidden-input {
    .ginput_complex {
      label {
        display: none;
      }
    }
  }
}

.gform_heading {
  margin-bottom: $spacer;
}

.gfield.title_hidden {
  &:not(:last-child) {
    margin-bottom: 0;
  }
  > .gfield_label {
    display: none;
  }
}

.gfield .textarea.small {
  height: 4rem;
}

input[type="submit"].gform_button {
  @extend .btn;
  @extend .btn-default !optional;
  @extend .btn-lg;
  cursor: pointer;
  width: 100%;
  margin: auto;
  display: block;
}

.gfield_error {
  color: #a94442;
  .gfield_description.validation_message {
    font-weight: 600;
  }
}

.ginput_complex .ginput_left,
.ginput_complex .ginput_right {
  display: inline-block;
}

.ginput_complex {
  .ginput_full,
  .ginput_left,
  .ginput_right {
    label {
      display: block;
    }
  }
  @include media-breakpoint-down(sm) {
    .ginput_left {
      padding-right: 0;
      width: 100%;
      max-width: 100%;
    }
    .ginput_right {
      padding-left: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}
.ginput_complex label,
.gfield_description {
  color: $gray-800;
  font-size: $font-size-sm;
}

.gform_wrapper li.gfield .ginput_container select {
  height: 2.7rem;
  padding-top: 5px;
}

.gform_wrapper li.field_sublabel_above label {
  margin-bottom: 0;
  margin-top: .5rem;
}

.gform_wrapper .gform_body {
  width: 100% !important;
}

@include media-breakpoint-up(xs) {
  .gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium,
  .gform_wrapper .top_label input.large.datepicker, .gform_wrapper .top_label input.medium.datepicker, .gform_wrapper .top_label input.small.datepicker,
  .top_label div.ginput_complex.ginput_container.gf_name_has_1,
  .top_label div.ginput_complex.ginput_container.gf_name_has_2,
  .top_label div.ginput_complex.ginput_container.gf_name_has_3 {
    width: 100%;
  }
  .gform_wrapper ul.gform_fields li.gfield {
    padding-right: 0;
  }
}

@include media-breakpoint-down(sm) {
  div.ginput_container_name span:last-child {
    padding-right: 0;
  }
}

#ui-datepicker-div {
  background-color: #fff;
}