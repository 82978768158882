@import "../../bower_components/bootstrap/scss/_functions.scss";
@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower

@import "common/mixins";
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "components/animation";
@import "components/galleries";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

@import "../fonts/font.css";
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,600i');