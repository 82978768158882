.sidebar {
  .widget {
    margin-bottom: map-get($spacers, 5);
    list-style: none;
    h3 {
      color: $chriscan-dark-gray;
      font-weight: 600;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      padding: map-get($spacers, 1) 0;
      a:hover {
        text-decoration: none;
      }
    }
    .search-form {
      flex-wrap: nowrap;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    input[type="search"],
    input[type="text"] {
      background-color: transparent;
      border: 1px solid $chriscan-light-gray;
      color: $chriscan-gray;
      padding: .6rem 1.5rem;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .search-submit {
      margin: 0;
    }

    label.gfield_label {
      display: none;
    }

    &-breadcr {

      .item-home {
        display: none;
      }

      .item-current {
        color: $chriscan-red;
      }
      .breadcrumbs li {
        &:nth-child(3) {
          padding-left: 1rem;
        }
        &:nth-child(4) {
          padding-left: 1.5rem;
        }
        &:nth-child(5) {
          padding-left: 2rem;
        }
        &:nth-child(6) {
          padding-left: 2.5rem;
        }
      }
    }
  }
  .gform_widget {
    li {
      padding: 0;
    }
    form {
      position: relative;
    }
    .gform_footer {
      position: absolute;
      top: 0;
      right: 0;

      @include media-breakpoint-down(md) {
        position: relative;
      }
    }
    input[type="email"] {
      @include media-breakpoint-up(lg) {
        padding-right: 110px;
      }
    }
    input[type="submit"] {
      padding: .75rem 1.5rem;
    }
  }
}