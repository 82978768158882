.btn {
  text-transform: uppercase;
  font-size: $font-size-sm;
  letter-spacing: .05rem;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.btn-transparent {
  background-color: transparent;
  border-color: $white;
  color: $white;
  &:hover, &:active, &:focus {
    background-color: $white;
    color: $gray-800;
  }
}