/**
 * Global Variabless
 *
 * Summary:
 *
 *  0. Sections
 *  1. Typography
 *  2. Backgrounds
 *  3. Miscellanous
 *  - 0.1 Wrappers
 *  - 0.2 Extenders
 *  - 0.3 Box Offset
 *  - 0.4 Logos
 *  4. Shortcodes
 *  - 0.1 CTA
*/

/* ==========================================================================
   0. Sections
   ========================================================================== */
body {
  background-color: $white;
}

body.logged-in {
  header.banner {
    top: 2rem;
    @include media-breakpoint-down(sm) {
      top: 46px;
    }
  }
  .fixed_top_show {
    top: 2rem!important;
    @include media-breakpoint-down(sm) {
      top: 46px!important;
    }
  }
}

.section {
  position: relative;
  max-width: 100%;
    
  // Padding: Default
  padding: ($spacer * 3) 0;
  @include media-breakpoint-up(md) {
    padding: ($spacer * 4) 0;
  }
  @include media-breakpoint-up(lg) {
    padding: ($spacer * 5) 0;
  }
  // Padding Sizes
  &.padding-large {
    padding: ($spacer * 5) 0;
    @include media-breakpoint-up(md) {
      padding: ($spacer * 6) 0;
    }
    @include media-breakpoint-up(lg) {
      padding: ($spacer * 7) 0;
    }
  }
  &.padding-small {
    padding: ($spacer * 3) 0 !important;
  }
  &.padding-no-top {
    padding-top: 0
  }
  &.padding-no-bottom {
    padding-bottom: 0;
  }
  &.padding-none {
    padding-top: 0;
    padding-bottom: 0;
  }
  p {
    color: $chriscan-dark-gray;
  }
}

/* ==========================================================================
   1. Typography
   ========================================================================== */
h1, h2 {
  b {
    color: theme-color("primary");  
    font-weight: 300;
  }
  color: theme-color("primary");
}
h3, h4, h5, h6 {
  letter-spacing: 0.07125rem;
  text-transform: uppercase
}

/* ==========================================================================
   2. Backgrounds
   ========================================================================== */
&.bg-cover {
  background-position: 50% 50%;
  background-size: cover;
  text-decoration: none;
}
&.bg-cover-transparency {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
&.bg-light {
  background-color: $gray-300 !important;
  color: $black;
  h1, h2, h3, h4, h5, h6 {
    color: $gray-700;
  }
  a {
    color: $blue;
    &:hover {
      text-decoration: none;
    }
  }  
}
&.bg-lighter {
  @extend .bg-light;
  background-color: $gray-100 !important;
}
&.bg-lightest {
  @extend .bg-light;
  background-color: $gray-100 !important;
}
&.bg-dark {
  background-color: $gray-700 !important;
}
&.bg-darker {
  background-color: $gray-800 !important;
}
&.bg-darkest {
  background-color: $gray-900 !important;
}
&.bg-primary {
  background-color: $blue !important;
}
&.bg-image {
  @extend .bg-cover;
}

/* ==========================================================================
   3. Miscellaneous
   ========================================================================== */

  /* 0.1 Wrappers
   ========================================================================== */
  .wrapper-small {
    @extend .mx-auto;
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(8);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }  
  }
  .wrapper-medium {
    @extend .mx-auto;
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(10);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }    
  }
  .wrapper-large {
    @extend .mx-auto;
    @include make-col-ready();
    @include media-breakpoint-up(lg) {
      @include make-col(10);
    }    
  }
  .wrapper-full {
    @include make-col-ready();
    @include media-breakpoint-up(lg) {
      @include make-col(12);
    }  
  }
  
  /* 0.2 Extenders
   ========================================================================== */
   .centered {
      @include media-breakpoint-up(sm) {
        text-align: center;
      }
   }
   .shadow {
    box-shadow: 0px 0px 40px 0px $black;
    transition: all 0.1s ease-in-out;
      &:hover {
        box-shadow: 0px 0px 40px 0px $chriscan-dark-gray;
      }
   }

  /* 0.3 Box Offset
   ========================================================================== */
  .box-offset {
    //margin: 0 ($spacer * 2) ($spacer * 2) 0;
    position: relative;
    //@include media-breakpoint-down(md) {
    //  margin: 0 ($spacer * 1) ($spacer * 3) 0;
    //}
    //&:after {
    //  content: '';
    //  background-color: $blue;
    //  @include gradient-directional($blue, $chriscan-dark-gray, 315deg);
    //  position: absolute;
    //  width: 65%;
    //  height: 65%;
    //  z-index: -1;
    //  top: auto;
    //  right: -($spacer * 2);
    //  left: auto;
    //  bottom: -($spacer * 2);
    //  @include media-breakpoint-down(md) {
    //    margin-left: ($spacer * 1);
    //    margin-top: ($spacer * 1);
    //    width: 100%;
    //    height: 100%;
    //    top: 0;
    //    right: 0;
    //    left: 0;
    //    bottom: 0;
    //  }
    //}
    br {
      display: none;
    }
    p:empty {
      display: none;
    }
  }
/* 0.4 Logos
   ========================================================================== */
  .logo-brand  {
    @include img-retina('../images/logo.png', '../images/logo@2x.png', 130px, 30px);
    @include size(130px, 30px);
    background-size: 130px 30px;
    text-indent: -9999px;
    display: block;
    margin-top: 0;
  }  

/* ==========================================================================
   3. Miscellaneous
   ========================================================================== */

/* 4.1 CTA
   ========================================================================== */
.cta {
  color: $chriscan-red;
  display: inline-block;
  margin-top: $spacer * 1;
  position: relative;
  line-height: 1.3rem;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    &:before {
      width: 20px;
      margin-right: .5rem;
    }
  }
  &:before {
    content: '';
    position: relative;
    top: 40%;
    display: inline-block;
    width: 0;
    margin-right: 0;
    margin-bottom: .22rem;
    height: 1px;
    left: 0;
    opacity: 1;
    transition: width .2s, margin .2s;
    background-color: $chriscan-red;
  }
  &:after {
    content: '';
    position: absolute;
    top: calc(40% + 2px);
    display: inline-block;
    width: 20px;
    height: 1px;
    right: -2rem;
    opacity: 1;
    transition: width .2s, margin .2s;
    background-color: $chriscan-red;
  }
}

/* ==========================================================================
   5. Helper classes
   ========================================================================== */

.no-scroll {
  overflow: hidden;
}

.vertical-center {
  position: relative;

  div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.p-sides-3 {
  padding: 0 3rem!important;
  @include media-breakpoint-up(lg) {
    padding: 0 4rem!important;
  }
}

.full-width-container {
  @include media-breakpoint-up(sm) {
    max-width: 100%!important;
    padding: 0;
  }

  .wrapper-full {
    padding: 0;
  }
  .row {
    margin: 0;
  }
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}